.header {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav {
    display: flex;
    align-items: center;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    margin-right: 20px;
  }
  
  .menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .menuItem {
    margin-right: 20px;
    color: #fff;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .menuItem:hover {
    color: #ccc;
  }
  
  .menuItem:last-child {
    margin-right: 0;
  }