@tailwind base;
@tailwind components;
@tailwind utilities;

.hero {
  background-image: linear-gradient(to bottom, #f7f7f7, #e7e7e7);
  background-size: 100% 300px;
  background-position: 0% 100%;
  transition: background-position 0.5s ease-out;
}

.hero:hover {
  background-position: 100% 100%;
}

.skill {
  transition: transform 0.3s ease-out;
}

.skill:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.animate__animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}